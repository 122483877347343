export const registerHText = 'Non egestas malesuada ut nisi. Lectus vel, tempus sodales vel id mattis condimentum vestibulum. Iaculis mattis sit sed enim nisl commodo eget morbi ipsum. Cras magna orci auctor sapien ullamcorper commodo eleifend.';

export const options = [
    {
        id:0,
        label:'Content Creator',
        value:'Content Creator'
    },
    {
        id:1,
        label:'Influencer',
        value:'Influencer'
    },
    {
        id:2,
        label:'Artist',
        value:'Artist'
    },
    {
        id:3,
        label:'Blogger',
        value:'Blogger'
    },
    {
        id:4,
        label:'Videographer',
        value:'Videographer'
    },
    {
        id:5,
        label:'Other',
        value:'Other'
    },
]

export const url = 'https://renaissance-30108.botics.co/api/v1/';