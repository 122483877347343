import * as yup from 'yup';

const registerValidationSchema = yup.object().shape({
    fname: yup
    .string()
    .required('First Name is Required'),
    phone: yup
    .string()
    .required('Phone Number is Required'),
    
});

export {registerValidationSchema};