import React, { useEffect, useRef, useState } from "react";
import { Form, Tabs, Tab } from "react-bootstrap";
import Box from "@mui/material/Box";
import { options, registerHText } from "../utils/constant";
import { useHistory } from "react-router";
import MobileMain from "./mobileMain";
import MenuBar from "../components/menubar";
const Main = ({ ...props }) => {
  const [preanimate] = useState(true);
  const [animate, setAnimate] = useState(false);
  const [blur, showBlur] = useState(false);
  const [download, setDownload] = useState(true);
  const [img_1_width_responsive, setimg_1_width_responsive] = useState(135);
  const [img_1_h_responsive, setimg_1_h_responsive] = useState(50);
  const [img_2_width_responsive, setimg_2_width_responsive] = useState(60);
  const [img_2_height_responsive, setimg_2_height_responsive] = useState(55);
  const [img_1_left_responsive, setImg_1_left_responsive] = useState(5);
  const [img_2_right_responsive, setImg_2_right_responsive] = useState(5);
  const [marginTop_img2_responsive, setmarginTop_img2_responsive] = useState(0);
  const [marginTop_img2, setmarginTop_img2] = useState(0);

  let img_1_width_responsive_ref = useRef();

  const [img_1_width, setimg_1_width] = useState(100);
  const [img_1_h, setimg_1_h] = useState(60);
  const [img_2_width, setimg_2_width] = useState(80);
  const [img_2_height, setimg_2_height] = useState(60);
  const [img_1_left, setImg_1_left] = useState(5);
  const [img_2_right, setImg_2_right] = useState(5);
  let img_1_width_ref = useRef();

  useEffect(() => {
    if (animate) {
      img_1_width_responsive_ref.current = setInterval(() => {
        setimg_2_height_responsive(
          (img_2_height_responsive) => img_2_height_responsive + 1.2
        );

        if (img_1_left_responsive > 0) {
          setImg_1_left_responsive(
            (img_1_left_responsive) => img_1_left_responsive - 0.2
          );
        }
        if (img_2_right_responsive > 0) {
          setImg_2_right_responsive(
            (img_2_right_responsive) => img_2_right_responsive - 0.2
          );
        }

        if (marginTop_img2_responsive < 20) {
          setmarginTop_img2_responsive(
            (marginTop_img2_responsive) => marginTop_img2_responsive + 0.5
          );
        }

        if (img_1_h_responsive < 90) {
          setimg_1_h_responsive(
            (img_1_h_responsive) => img_1_h_responsive + 0.4
          );
        }
      }, 10);
    }
  }, [animate]);

  useEffect(() => {
    if (animate) {
      setTimeout(() => {
        showBlur(true);
      }, 70);

      setDownload(false);
    }
  }, [animate]);

  useEffect(() => {}, [img_1_width_responsive]);

  useEffect(() => {
    if (img_1_h_responsive > 70) {
      clearInterval(img_1_width_responsive_ref.current);
      // setTimeout(() => {
      //   props.setShowMain(false);
      // }, 90);


      setTimeout(() => {
        props.history.push({
          pathname: "/register",
        });
      }, 1000);


    }
  }, [img_1_h_responsive]);

  useEffect(() => {
    if (animate) {
      img_1_width_ref.current = setInterval(() => {
        setimg_1_width((img_1_width) => img_1_width + 4.5);
        setimg_2_width((img_2_width) => img_2_width + 3.7);
        setimg_2_height((setimg_2_height) => setimg_2_height + 2.5);

        if (img_1_left > 0) {
          setImg_1_left((img_1_left) => img_1_left - 0.6);
        }
        if (img_2_right > 0) {
          setImg_2_right((img_2_right) => img_2_right - 0.6);
        }

        if (marginTop_img2 < 50) {
          setmarginTop_img2((marginTop_img2) => marginTop_img2 + 0.5);
        }

        if (img_1_h < 90) {
          setimg_1_h((img_1_h) => img_1_h + 1.4);
        }
      }, 30);
    }
  }, [animate]);

  useEffect(() => {
    if (animate) {
      setTimeout(() => {
        showBlur(true);
      }, 2000);
      setDownload(false);
    }
  }, [animate]);

  useEffect(() => {
    if (img_1_width > 153) {
      clearInterval(img_1_width_ref.current);
      // setTimeout(() => {
      //   props.setShowMain(false);
      // }, 300);


      setTimeout(() => {
        props.history.push({
          pathname: "/register",
        });
      }, 1000);
    }
  }, [img_1_width]);

  return (
    <div className="bg_main">
      <div
        className="bg_landing"
        style={{
          overflow: "hidden",
        }}
      >
          <MenuBar
           history={props.history}
           excep={true}
           />
        {preanimate && (
          <>
            <div
              className="man1_img_div"
              style={{
                width: `${img_1_width}%`,
                overflow: "hidden",
                left: `-${img_1_left}%`,
                display: "flex",
                alignItems: "flex-end",
                height: "100vh",
              }}
            >
              <img
                src={require("../assets/man2-2.png").default}
                style={{
                  width: "50%",
                  height: `${img_1_h}%`,
                }}
              />
            </div>

            <div
              className="man1_img_div_responsive"
              style={{
                width: `${img_1_width_responsive}%`,
                overflow: "hidden",
                left: `-${img_1_left_responsive}%`,
              }}
            >
              <img
                src={require("../assets/man2-2.png").default}
                style={{
                  width: "50%",
                  height: `${img_1_h_responsive}%`,
                }}
              />
            </div>

            {!animate ? (
              <>
                <div className="logo_div">
                  <div
                    className="logo"
                    style={{
                      height: "auto",
                      position: "relative",
                      zIndex: 1000,
                    }}
                  >
                    <img
                      src={require("../assets/bgname.png").default}
                      className="mt-5"
                      style={{ width: "30%", minWidth: 200, }}
                    />
                    <img
                      src={require("../assets/bglogo.png").default}
                      style={{ width: "34%", minWidth: 200,marginTop:50 }}
                    />

                    <button
                      onClick={() => setAnimate(true)}
                      // onClick={() =>
                      //   props.history.push({
                      //     pathname: "/register",
                      //   })
                      // }
                      className="lgcenterbtn"
                      style={{top:'57%'}}
                    >
                      <h3 className="text-white openSans fs-14 mt-2">
                        Join The Wait List
                      </h3>
                    </button>
                  </div>
                </div>
              </>
            ) : null}

            {blur ? (
              <div className="blur_img_div">
                <img
                  src={require("../assets/blur.png").default}
                  className="blur_img"
                />
              </div>
            ) : null}

            <div
              className="man2_img_div"
              style={{
                width: `${img_2_width}%`,
                overflow: "hidden",
                display: "flex",
                justifyContent: "flex-end",
                right: `-${img_2_right}%`,
                height: "100vh",
              }}
            >
              <img
                src={require("../assets/man1-1.png").default}
                className="man2_img"
                style={{
                  width: "43%",
                  height: `${img_2_height}%`,
                  position: "absolute",
                  top: `${marginTop_img2}%`,
                }}
              />
            </div>

            <div
              className="man2_img_div_responsive"
              style={{
                width: `${img_2_width_responsive}%`,
                right: `-${img_2_right_responsive}%`,
                height: "100vh",
              }}
            >
              <img
                src={require("../assets/man1-1.png").default}
                className="man2_img"
                style={{
                  width: "100%",
                  height: "55%",
                  position: "absolute",
                  top: `${marginTop_img2_responsive}%`,
                }}
              />
            </div>

            {download ? (
              <div className="bottom_download_div">
                <p className="text-lgc comming_soon_text text-lgc">Coming soon!</p>
                <div style={{ display: "flex" }}>
                  <div className="app_store_div">
                    <img
                      src={require("../assets/apple.png").default}
                      className="apple_img"
                    />
                    <div>
                      <p className="download_text">DOWNLOAD ON THE</p>
                      <p className="app_store_text">App Store</p>
                    </div>
                  </div>
                  <div className="play_store_div">
                    <img
                      className="play_store_img"
                      src={require("../assets/play-store.png").default}
                    />
                    <div>
                      <p className="download_text">GET IT ON</p>
                      <p className="app_store_text">Google Play</p>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
      <div className="mobile_main">
        <MobileMain history={props.history} />
      </div>
    </div>
  );
};

export default Main;
