// import React, { useState } from "react";
// import logo from "./logo.svg";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap.js";
// import "./css/main.scss";
// import {
//   BrowserRouter as Router,
//   Route,
//   Switch,
//   Redirect,
// } from "react-router-dom";
// import { StyledEngineProvider } from "@mui/material/styles";
// import Register from "./screens/register";
// import Main from "./screens/main";
// import About from "./screens/about";
// import { useHistory } from "react-router";

// function App() {
//   const [showMain, setShowMain] = useState(true);
//   // if (!showMain) {
//   //   return <Register />;
//   // } else {
//   return <Main setShowMain={setShowMain} />;
//   // }

//   // return (
//   //   <Router>
//   //             <Switch>

//   //               <Route
//   //               path="/"
//   //               exact
//   //               render={(props) => <Main {...props} />}
//   //               />
//   //             <Route
//   //               path="/about-us"
//   //               exact
//   //               render={(props) => <About {...props} />}
//   //             />
//   //             <Route
//   //               path="/register"
//   //               exact
//   //               render={(props) => <Register {...props} />}
//   //             />

//   //             </Switch>
//   //           </Router>
//   // );
// }

// export default App;



import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import './css/main.scss';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import Register from './screens/register';
import Main from './screens/main';
import About from './screens/about';
import { useHistory } from 'react-router';


function App() {
  return (
    <Router>
              <Switch>
                
                <Route
                path="/"
                exact
                render={(props) => <Main {...props} />}
                />
              <Route
                path="/about-us"
                exact
                render={(props) => <About {...props} />}
              />
              <Route
                path="/register"
                exact
                render={(props) => <Register {...props} />}
              />
             
            
              </Switch>
            </Router>
  );
}

export default App;
