import React, { useEffect, useRef, useState } from "react";
import "../css/style.scss"
import "../css/mobile.scss"
import MenuBar from "../components/menubar";

const Main = ({ ...props }) => {
  const [preanimate] = useState(true);
  const [animate, setAnimate] = useState(false);
  const [blur, showBlur] = useState(false);
  const [download, setDownload] = useState(true);
  const [img_1_width_responsive, setimg_1_width_responsive] = useState(135);
  const [img_1_h_responsive, setimg_1_h_responsive] = useState(20);
  const [img_2_width_responsive, setimg_2_width_responsive] = useState(40);
  const [img_2_height_responsive, setimg_2_height_responsive] = useState(55);
  const [img_1_left_responsive, setImg_1_left_responsive] = useState(5);
  const [img_2_right_responsive, setImg_2_right_responsive] = useState(5);
  const [marginTop_img2_responsive, setmarginTop_img2_responsive] = useState(0);
  const [marginTop_img2, setmarginTop_img2] = useState(0);

  let img_1_width_responsive_ref = useRef();
  useEffect(() => {
    if (animate) {
      img_1_width_responsive_ref.current = setInterval(() => {
        setimg_2_width_responsive(
          (img_2_width_responsive) => img_2_width_responsive + 0.5
        );
        setimg_1_width_responsive(
          (img_1_width_responsive) => img_1_width_responsive + 3.3
        );

        if (img_1_left_responsive > 0) {
          setImg_1_left_responsive(
            (img_1_left_responsive) => img_1_left_responsive - 0.2
          );
        }
        if (img_2_right_responsive > 0) {
          setImg_2_right_responsive(
            (img_2_right_responsive) => img_2_right_responsive - 0.2
          );
        }

        if (marginTop_img2_responsive < 20) {
          setmarginTop_img2_responsive(
            (marginTop_img2_responsive) => marginTop_img2_responsive + 0.5
          );
        }

        // if (img_1_h_responsive < 90) {
        //   setimg_1_h_responsive(
        //     (img_1_h_responsive) => img_1_h_responsive + 0.4
        //   );
        // }
      }, 10);
    }
  }, [animate]);

  // useEffect(() => {
  //   if (animate) {
  //     // setTimeout(() => {
  //     //   showBlur(true);
  //     // }, 200);

  //     setDownload(false);
  //   }
  // }, [animate]);

  useEffect(() => {
    if (img_2_width_responsive > 50) {
      clearInterval(img_1_width_responsive_ref.current);
      setTimeout(() => {
        props.history.push({
          pathname: "/register",
        });      }, 1500);
    }
  }, [img_2_width_responsive]);

  useEffect(() => {
    if (animate) {
      setTimeout(() => {
        showBlur(true);
      }, 200);
      setDownload(false);
    }
  }, [animate]);

  return (
    <div
      className="bg_landing1"
      style={{
        overflow: "hidden",
      }}
    >
      <MenuBar
       history={props.history}
       excep={true}
      />
      {preanimate && (
        <>
          <div
            className="man1_img_div_responsive"
            style={{
              width: `${img_1_width_responsive}%`,
              overflow: "hidden",
              left: `-${img_1_left_responsive}%`,
              // display: "flex",
              // alignItems: "flex-end",
              height: "100vh",
            }}
          >
            <img
              src={require("../assets/left-hand.png").default}
              style={{
                width: "30%",
                // height: `${img_1_h_responsive}%`,
              }}
            />
          </div>

          {
            !animate ? (
              <>
                <div className="logo_div">
                  <div
                    className="logo"
                    style={{
                      // height: "auto",
                      // position: "relative",
                      // zIndex: 1000,
                    }}
                  >
                    <img
                      src={require("../assets/bglogo.png").default}
                      style={{ width: "30%", minWidth: 200 }}
                    />
                    <img
                      src={require("../assets/bgname.png").default}
                      // className="mt-5"
                      style={{ width: "30%", minWidth: 200 }}
                    />

                    <button
                      onClick={() => setAnimate(true)}
                      // onClick={() =>
                      //   props.history.push({
                        //     pathname: "/register",
                      //   })
                      // }
                      className="lgcenterbtnmobile"
                      >
                      <h3 className="text-white fs-14 mt-2 join-btn">
                        Wait List
                      </h3>
                    </button>
                  </div>
                </div>
              </>
            ) : null
            // <div className="blur_img_div">
            //   {img_1_width > 153 && (
            //     <img
            //       src={require("../assets/blur.png").default}
            //       className="blur_img"
            //     />
            //   )}
            // </div>
          }

          {blur ? (
            <div className="blur_img_div">
              <img
                src={require("../assets/blur.png").default}
                className="blur_img"
              />
            </div>
          ) : null}

          <div
            className="man2_img_div_responsive"
            style={{
              width: `${img_2_width_responsive}%`,
              right: `-${img_2_right_responsive}%`,
              height: "100vh",
            }}
          >
            <img
              src={require("../assets/right-hand.png").default}
              className="man2_img"
              style={{
                width: "100%",
                // height: `${img_2_height_responsive}%`,
                // height: "30%",
                position: "absolute",
                // top: `${marginTop_img2_responsive}%`,
              }}
            />
          </div>

          {download ? (
            <div className="bottom_download_div">
              <p className="comming_soon_text text-white">Coming soon!</p>
              <div >
                <div className="app_store_div">
                  <img
                    src={require("../assets/apple.png").default}
                    className="apple_img"
                  />
                  <div>
                    <p className="download_text">DOWNLOAD ON THE</p>
                    <p className="app_store_text">App Store</p>
                  </div>
                </div>
                <div className="play_store_div">
                  <img
                    className="play_store_img"
                    src={require("../assets/play-store.png").default}
                  />
                  <div>
                    <p className="download_text">GET IT ON</p>
                    <p className="app_store_text">Google Play</p>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Main;