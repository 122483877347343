import React,{useEffect, useState} from 'react';
import {Form, Tabs, Tab} from 'react-bootstrap'
import Box from '@mui/material/Box';
import { options, registerHText } from '../utils/constant';
import { useHistory } from 'react-router';
import MenuBar from '../components/menubar';


const About = ({...props}) => {
    // const {history} = props;
    const [menutoggle, setmenutoggle] = useState(false);
    useEffect(() => {
        console.log(props)
    },[])
    return(
        <div className="bg"  style={{
            position:'relative',
            overflow:'hidden'
        }}>
            <div className="bg-about">
           <MenuBar
           history={props.history}
           />
            <div className="row w-100 m-0" style={{paddingTop:'5%',paddingBottom:'10%'}}>
                <div className="col-lg-5 col-lg-2 col-md-5 offset-md-2 col-sm-12 p-4" style={{ position:'relative'}}>
                    <h4 className="about-head mt-5">Sustainable Social Media</h4>
                 <p className="about-desc mt-4 ex25">Renaissance is the perfect tool for the Creator Economy. Using our app, everyone can become the creator that they are meant to be. On our sustainable platform, creators can receive subscriptions and gratuities from their audiences directly on their social media content. </p>
                    <p className="about-desc ex25">
                    Creators will then have the ability to give a percentage of their earnings to the Earth through our environmental branch while Renaissance cleans debris from our world, oceans, and beaches; and replants wild landscapes with trees and grasslands.
                    </p>
                    <p className="about-desc ex25">
                    Our proprietary algorithm is being designed to restore balance to both our mental and environmental health. The vibrational algorithm allows conscious creators to visualize the impact that their content is making by revealing the way that each of their posts makes their audiences feel. 
                    </p>
                    <div className="logo-rect"> 
                   {/* <img
                    src={require('./../assets/logo-rect3.png').default}
                    style={{margin:'auto',display:'block'}}
                    /> */}
                    {/* <div style={{marginTop:10}}/> */}
                    {/* <img
                      src={require('./../assets/res-text.png').default}
                      style={{margin:'auto',display:'block',width:100}}
                    /> */}
                </div>
                 
                </div>
                <div className="col-md-4">
                    <div className="align-right-sec-con">
                    <div className="align-right-sec">
                        <h4 className="about-right-head">DIVINE</h4>
                        <p className="about-right-para">Are you evolving beyond the current social media paradigm?</p>
                    </div>
                    <div className="align-right-sec">
                        <h4 className="about-right-head">BY</h4>
                        <p className="about-right-para">Join us in laying the foundation of a fifth dimensional platform.</p>
                    </div>
                    <div className="align-right-sec">
                        <h4 className="about-right-head">DESIGN</h4>
                        <p className="about-right-para">Have fun creating and sharing beauiful content while saving the Earth.</p>
                    </div>
                    </div>
                    
                </div>
                <div className='col-md-4 offset-md-4'>
               
                </div>
            </div>
          
                  
            </div>


         {/* <div className="bg about-bottom" >
            <div className="row">
                <div className="col-md-6 col-sm-12 col-xs-10 offset-xs-1 offset-md-1 ld-portion">
                <h4 className="about-head mt-5">Looking For Leaders</h4>
                <p className="about-desc mt-4">
                The #socialmediarenaissance is having a <b>Content Creation Contest</b> to select <b>111 leaders</b> to join in the beta test of our new XR social media application before it goes live.
                </p>
                <br/>
                <p className="about-desc">
                As a beta tester, you will get:
                </p>
                <ul>
                    <li className="about-desc" style={{lineHeight: '25px'}}>The first look at our new platform.</li>
                    <li className="about-desc" style={{lineHeight: '25px'}}>The ability to test out our algorithm and provide feedback, so that we can perfect it for the collective together.</li>
                    <li className="about-desc" style={{lineHeight: '25px'}}>The discovery of our app's XR features and the ability to explore what that means for you, as a creator.</li>
                    <li className="about-desc" style={{lineHeight: '25px'}}>The first look at our 6 other innovative features unknown to the public at this time.</li>
                    <li className="about-desc" style={{lineHeight: '25px'}}>A head start gaining influence on our platform with the option to keep your profile active after the beta testing phase, and</li>
                    <li className="about-desc" style={{lineHeight: '25px'}}>The ability to start supporting the environment right now in an impactful way.</li>
                </ul>
                <div className=''>
                <br/>
                <p className="about-desc">
                All you have to do to enter our contest is 
                </p>
            
                <ol>
                    <li className="about-desc">
                    Follow our instagram <a href="https://www.instagram.com/xr.renaissance/?hl=en" target="__blank"
                    style={{color:'white'}}
                    >@xr.renaissance</a>
                    </li>
                    <li className="about-desc">
                    Create a piece of content explaining what Renaissance means to you, and
                    </li>
                    <li className="about-desc">
                    Post your creation to one of your social media accounts using the hashtag #socialmediarenaissance
                    </li>
                </ol>
                <br/>
                <p className="about-desc ex15">
                You may enter as many times as you feel called to until March 25th, 2022 when our panel of judges will be selecting the 111 winners of our <b>Content Creation Contest</b>. We hope that our company will bring a personal renaissance to many while providing a revitalization to social media, the environment, mental health, and the 21st Century.

                </p>
                <br/>
                <h4 className="about-right-head" style={{textTransform:'uppercase'}}>What does Renaissance mean to you?</h4>
                </div>
                </div>
                <div className="col-md-3 col-sm-12 offset-md-1 mt-5 ">
                <div className="content-box">
    
                <p className="text-white text-center exbold">Contest Terms & Conditons</p>
                <ol>
                    <li className="text-white excls">
                    The promoter is: XRENAISSANCE, Inc. whose office is registered at 225 Douglas Parkway, Cape Coral, FL 33993.
                    </li>
                    <li className="text-white">
                    The competition is open to residents worldwide aged 18 years or over except employees of XRENAISSANCE, Inc. and their close relatives and anyone otherwise connected with the organisation or judging of the competition.
                    </li>
                    <li className="text-white">
                    There is no entry fee and no purchase necessary to enter this competition.
                    </li>
                    <li className="text-white">
                    By entering this competition, an entrant is indicating his/her agreement to be bound by these terms and conditions.
                    </li>

                    <li className="text-white">
                    Route to entry for the competition and details of how to enter are posted in the call.
                    <br/>
                    State which social media platforms you are running the competition on.
                    </li>

                    <li className="text-white">
                    Multiple entries from the same person are allowed.
                    </li>
                    <li className="text-white">
                    Closing date for entry will be March 25, 2022. After this date no further entries to the competition will be permitted and the judging of all entries received will start.
                    </li>
                    <li className="text-white">
                    No responsibility can be accepted for entries not received for whatever reason.
                    </li>
                    <li className="text-white">
                    The rules of the competition and how to enter are as follows:
                    </li>
                    <li className="text-white">
                    Multiple entries from the same person are allowed.
                    (1) Follow our instagram  <a href="https://www.instagram.com/xr.renaissance/?hl=en" target="__blank"
                    style={{color:'white'}}
                    >@xr.renaissance</a> <br/>
                    (2) Create something showcasing what Renaissance means to you
                    <br/>
                    (3) Post your creation to one of your socials using the hashtag #socialmediarenaissance
                    Any form of content posted on Instagram, YouTube, TikTok, Twitter, or Facebook will be eligible for entry.
                    </li>
                    <li className="text-white">
                    The promoter reserves the right to cancel or amend this competition and these terms and conditions without notice in the event of a catastrophe, war, civil or military disturbance, act of God or any actual or anticipated breach of any applicable law or regulation or any other event outside of the promoter’s control. Any changes to the competition will be notified to entrants as soon as possible by the promoter.
                    </li>
                    <li className="text-white">
                    The promoter is not responsible for inaccurate prize details supplied to any entrant by any third party connected with this competition.
                    </li>
                    <li className="text-white">
                    The prize is as follows:<br/>
                    To be selected as one of the 111 beta testers of Renaissance.
                    <br/>
                    The prize is as stated and no cash or other alternatives will be offered. The prize is not transferable. Prizes are subject to availability and we reserve the right to substitute any prize with another of equivalent value without giving notice.
                    </li>

                    <li className="text-white">
                    Winners will be chosen: from all entries received and verified by the Promoter and its agents.
                    </li>
                    <li className="text-white">
                    The winner will be notified by email (or if not found) by DM on their social media within 28 days of the closing date. If the winner cannot be contacted or does not respond and claim their spot within 14 days of notification, we reserve the right to withdraw the prize from the winner and pick a replacement winner.
                    </li>
                    <li className="text-white">
                    The promoter will notify the winner how to activate their prize.
                    </li>
                     <li className="text-white">
                     By entering this competition, an entrant is indicating his/her agreement to be bound by these terms and conditions.
                    </li>
                    <li className="text-white">
                    The competition and these terms and conditions will be governed by USA law and any disputes will be subject to the exclusive jurisdiction of the courts of the United States.
                    </li>
                    <li className="text-white">
                    Entrants agree to the use of his/her content entry on promoters social media pages.
                    </li>

                    <li className="text-white">
                    Winners agree to the use of his/her name, image and/or content entry in any future publicity material. Any personal data relating to the winner or any other entrants will be used solely in accordance with current USA data protection legislation.
                    </li>
                    <li className="text-white">
                    The winner’s names will be available 28 days after the closing date by emailing the following address: hello@xrenaissance.art
                    </li>

                    <li className="text-white">
                    Entry into this competition will be deemed as acceptance of these terms and conditions.
                    </li>

                    <li className="text-white">
                    This promotion is in no way sponsored, endorsed or administered by, or associated with, Facebook, Twitter, Instagram, TikTok, YouTube or any other Social Network other than Renaissance.
                    </li>
                </ol>
                </div>
               
                  
                </div>
            </div>
         </div> */}
           
        </div>
    );
}

export default About;