import React, {useState} from 'react';
import styled from 'styled-components'

const Select = styled.div`
    height:150px;
    position: relative;
    overflow:auto;
    cursor:pointer;
`

const Option = styled.h4`
    height:35px;
    padding-top:7px;
    border-color:white;
    border-style:solid;
    border-width:${props => props.active ? '1px' : 0};
    border-left-width:0px;
    border-right-width:0px;
`

const SelectM = ({...props}) => {
    const {options, onSelect} = props;
    const [optionObj, setoptionObj] = useState(options);
    const [activeId, setactiveId] = useState(-1);
   
    const onSelection = (item) => {
        setactiveId(item.id)
        onSelect(item);
    }
    return(
        <Select>
            {optionObj.map((item, index) => {
                return(
                    <Option className="text-lgc normal fs-16 text-center" key={index} active={item.id == activeId} onClick={() => onSelection(item)}>{item.label}</Option>
                );
            })}
         
        </Select>
    );
}

export default SelectM;