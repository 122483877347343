import React,{useState} from 'react';
import {Form, Tabs, Tab} from 'react-bootstrap'
import Box from '@mui/material/Box';
import { options, registerHText, url } from '../utils/constant';
import { SelectM } from '../components';
import { Formik } from 'formik';
import { registerValidationSchema } from '../utils/validation';
import axios from 'axios';
import MenuBar from '../components/menubar';


const Register = ({...props}) => {
    const [checked, setchecked] = useState(false);

    const handleRegister =(values) => {
        const {fname, user_type, email, phone} = values;
        axios.post(`${url}subscribe/`,{
            first_name:fname,
            phone_number:phone,
        }).then(res => {
            alert('You are registered successfully!');
            props.history.push('/')
        }).catch(err => {
            alert('Something went wrong');
            console.log(err);
        })
    }
    return(
        <div className="bg">
             <MenuBar
           history={props.history}
           excep={true}
           />
          <div className="logo">
          <div className='d-block d-sm-none'
           style={{marginBottom:15}}
          >
            <img
              src={require('../assets/name.png').default}
              className="mt-1"
              />
              </div>
              <img
              src={require('../assets/logo.png').default}
              className='register-logo'
              />
            <div className='d-none d-md-block d-sm-block d-lg-block'>
            <img
              src={require('../assets/name.png').default}
              className="mt-1"
              />
              </div>
           
          </div>
        
            <Formik
                validationSchema={registerValidationSchema}
                enableReinitialize
                initialValues={{
                  fname:'',
                  email:'',
                  phone:'',
                  user_type:''
                }}
                onSubmit={values => handleRegister(values)}>
                   {({
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                      values,
                      errors,
                      touched,
                    }) => (
            <div className="form-container mt-4">
            <h4 className="text-white mt-3 fs-16 text-center">
                Join the Wait list
            </h4>
            <div className="input-box">
                <label className="mt-2 text-white fs-12 normal">First Name</label>
                <input
                className="input mt-1"
                value={values.fname}
                style={{borderBottomColor: (errors.fname && touched.fname) ? 'red' : '#fff'}}
                onChange={handleChange('fname')}
                />
            </div>
            <div className="input-box">
                <label className="mt-2 text-white fs-12 normal">Phone number</label>
                <input
                className="input mt-1"
                value={values.phone}
                style={{borderBottomColor: (errors.phone && touched.phone) ? 'red' : '#fff'}}
                onChange={handleChange('phone')}
                />
            </div>
            {/* <div className="input-box">
                <label className="mt-2 text-white fs-12 normal">Email</label>
                <input
                className="input mt-1"
                style={{borderBottomColor: (errors.email && touched.email) ? 'red' : '#fff'}}
                value={values.email}
                onChange={handleChange('email')}
                />
            </div>
            <div className="input-box">
                <label className="mt-2 text-white fs-12 normal">Are you a:</label>
                <SelectM
                options={options}
                onSelect={e => setFieldValue('user_type',e.value)}
                />
            </div> */}

            {/* <Form.Group className="mb-3 mt-4" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" 
                label="I'm interested in being a Renaissance beta tester"
                className="text-lgc text-center"
                onClick={() => setchecked(!checked)}
                />
            </Form.Group> */}

            <button 
            onClick={() => handleSubmit()}
            // disabled={!checked}
            style={{background:'none', border:'1px solid #fff',color:'#fff'}}
            className="submit-btn"
            >
                Submit
            </button>
           
            </div>
              )}
    
              </Formik>
        </div>
    );
}

export default Register;