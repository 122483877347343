import React,{useEffect, useState} from 'react';
import {Form, Tabs, Tab} from 'react-bootstrap'
import Box from '@mui/material/Box';
import { options, registerHText } from '../utils/constant';
import { useHistory } from 'react-router';

const menuObj = [
    {id:0, name: 'Home', routeTo:'/'},
    {id:1, name: 'About Us', routeTo:'/about-us'},
    {id:2, name: 'Test the Beta', routeTo:'https://docs.google.com/forms/d/e/1FAIpQLSe2h6MWY0rr45T8QhlSoVqdbjTdmewEVnJ8L_yc8dE8b1RD7Q/viewform?usp=sf_link'},
    {id:3, name: 'Our Social', routeTo:'https://www.instagram.com/xr.renaissance/?hl=en'},
]

const MenuBar = ({...props}) => {
    const {history, excep = false} = props;
    const [menutoggle, setmenutoggle] = useState(false);
    useEffect(() => {
        console.log(props)
    },[])
    return(
        <div>
            <button
              onClick={() => setmenutoggle(!menutoggle)}
          className="toggle-btn"
          style={{pointerEvents:'inherit',zIndex:1000000,}}
          >
              <img
              src={menutoggle ?  require('./../assets/toggle-to.png').default : require('./../assets/list.png').default}
              className='menuIcon'
              />
          </button>
          <div className={!excep ? "toggle-btn-c exceptional" : "toggle-btn-c"}>
          {menutoggle && menuObj.map((item,index) => {
              return(
            <button
            key={item.id}
            className="tgbtn"
            onClick={() => item.id >= 2 ? window.open(item.routeTo) : history.push(item.routeTo)}
            style={{pointerEvents:'inherit',cursor:'pointer'}}
          >
              {item.name}
          </button>
              );
          })}
</div>
           
        </div>
    );
}

export default MenuBar;